<template>
  <ion-list lines="none" style="border-bottom: darkgrey solid 3px">
    <ion-item class="ion-no-padding">
      <ion-label class="ion-text-wrap"> Total Students: {{ allStudents.length }} </ion-label>
    </ion-item>
    <ion-item class="ion-no-padding">
      <ion-label
        >Exam date: {{ moment(examSubject.exam_schedule.start).format("ll") }}
      </ion-label>
    </ion-item>
    <ion-item class="ion-no-padding">
      <ion-label
        >Time: {{ moment(examSubject.exam_schedule.start).format("LT") }}, to {{ moment(examSubject.exam_schedule.end).format("LT") }}
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-list v-if="examSubject">
    <ion-item class="ion-no-padding">
      <ion-label> Student Marks Updated </ion-label>
      <ion-badge slot="end" color="success" class="badge">
        {{ allStudents.length - countNotUpdated() }}
      </ion-badge>
    </ion-item>
    <ion-item class="ion-no-padding">
      <ion-label> Not Updated </ion-label>
      <ion-badge slot="end" color="warning" class="badge">
        {{ countNotUpdated() }}
      </ion-badge>
    </ion-item>
  </ion-list>

  <h5>
    Roll Numbers:
  </h5>

  <div
    class="container ion-margin-top ion-padding-top"
    v-if="allStudents"
  >
    <ion-badge
      class="badge-sm"
      v-for="item in allStudents"
      :key="item.id"
      :color="getBadgeColor(item)"
      @click="loadStudent(item.id)"
    >
      {{ item.student.roll_no }}
    </ion-badge>
  </div>
</template>

<script>
import { IonBadge, IonList, IonItem, IonLabel } from "@ionic/vue";
import moment from "moment";

export default {
  props: [
    "allStudents",
    "exam_name",
    "examSubject",
    "loadedStudent",
  ],
  emits: ["loadStudent"],
  components: {
    IonBadge,
    IonList,
    IonItem,
    IonLabel,
  },

  data() {
    return {
      moment,
    };
  },


  methods: {
    getBadgeColor(item) {
      return item.pivot.evaluated_by ? 'success' : 'warning';
    },

    countNotUpdated() {
      return this.allStudents.filter(
        (student) => student.pivot.evaluated_by == null
      ).length;
    },

    loadStudent(id) {
      this.$emit("loadStudent", id);
    },
  },

  mounted() {},
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.badge {
  border-radius: 35%;
  min-width: 1.8rem;
  min-height: 1.8rem;
  text-align: center;
  padding: 0.5rem;
}
.badge-sm {
  border-radius: 35%;
  min-width: 1.6rem;
  min-height: 1.6rem;
  text-align: center;
  padding: 0.4rem;
  margin: 0.4rem;
}
</style>